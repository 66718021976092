import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import bbox from "@turf/bbox";
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import {isNumeric} from "alpinejs/src/utils";




gsap.registerPlugin(ScrollToPlugin);

window.simpleMap = function(latitude, longitude){
  return {
    isLoading: false,
    latitude:latitude,
    longitude: longitude,
    init(){
      var size = 100;

      var pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

// get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
          var canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');
        },

// called once before every frame where the icon will be used
        render: function () {
          var duration = 1000;
          var t = (performance.now() % duration) / duration;

          var radius = (size / 2) * 0.3;
          var outerRadius = (size / 2) * 0.7 * t + radius;
          var context = this.context;

// draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = 'rgba(253, 255, 115,' + (1 - t) + ')';
          context.fill();

// draw inner circle
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
          );
          context.fillStyle = '#FDFF73';
          context.strokeStyle = '#0C69F5';
          context.lineWidth = 20;
          context.fill();
          context.stroke();

// update this image's data with data from the canvas
          this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
          ).data;

// continuously repaint the map, resulting in the smooth animation of the dot
          map.triggerRepaint();

// return `true` to let the map know that the image was updated
          return true;
        }
      };

      mapboxgl.accessToken = 'pk.eyJ1IjoiZXhwbG9zZSIsImEiOiI0dWFzekVRIn0.E2lrTxvCd9UTQ-uIpexvQA';
      let map = new mapboxgl.Map({
        container: 'shopmap',
        style: 'mapbox://styles/explose/ckewsautn092119pa9xql8uk6', // stylesheet location
        center: [latitude, longitude], // starting position [lng, lat]
        zoom: 16,
        pitch: 45
      });

      var language = new MapboxLanguage();
      language.language = document.documentElement.lang;
      map.addControl(language);


      map.on('load', function () {
        map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

        map.addSource('points', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [
              {
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates': [latitude, longitude]
                }
              }
            ]
          }
        });
        map.addLayer({
          'id': 'points',
          'type': 'symbol',
          'source': 'points',
          'layout': {
            'icon-image': 'pulsing-dot'
          }
        });
      });

      //console.log(this.mapbox);



    }
  }

}

window.mapWrapper = function(){
  return {
    isLoading: false,
    url: null,
    items: [],
    target:null,

    init(url){

      //Force to renew map in case of the wrapper

      if (document.getElementById('map')){
        if (!document.getElementById('map').classList.contains('mapboxgl-map')){
          window.mapbox = null;
        }
      }

      this.url = url;
      this.isLoading = true;

      if (!window.mapbox){
        this.setMap();
      }else{
        this.updateSource();
      }

    },

    updateSource(){

      fetch(this.url)
        .then(res => res.json())
        .then(geojson => {
          window.mapbox.getSource('shops').setData(geojson);
          window.mapbox.fitBounds(bbox(geojson), {padding: 20});
        });
    },

    setMap(){
      mapboxgl.accessToken = 'pk.eyJ1IjoiZXhwbG9zZSIsImEiOiI0dWFzekVRIn0.E2lrTxvCd9UTQ-uIpexvQA';
      window.mapbox = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/explose/ckewsautn092119pa9xql8uk6', // stylesheet location
        center: [6.1319346, 49.611621], // starting position [lng, lat]
        zoom: 11,
        pitch: 45
      });

      var language = new MapboxLanguage();
      language.language = document.documentElement.lang;
      window.mapbox.addControl(language);

      window.mapbox.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );

      window.mapbox.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );

      window.mapbox.on('load', this.mapLoad.bind(this));

      window.mapbox.on('data', function(e) {
        if (e.isSourceLoaded){
          //Do somethings ?
        }
      }.bind(this));

    },

    mapLoad(){
      this.isLoading = false;
      window.mapbox.resize();

      window.mapbox.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#C4CAD2',

// use an 'interpolate' expression to add a smooth transition effect to the
// buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      });

      window.mapbox.addSource('shops', {
        type: 'geojson',
        data:null,
        cluster: true,
        clusterMaxZoom: 17, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });
      this.updateSource();


      window.mapbox.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'shops',
        filter: ['has', 'point_count'],
        paint: {
// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
// with three steps to implement three types of circles:
//   * Blue, 20px circles when point count is less than 100
//   * Yellow, 30px circles when point count is between 100 and 750
//   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': '#13294B',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      window.mapbox.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'shops',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          "text-color": "#ffffff"
        }
      });

      window.mapbox.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'shops',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#FDFF73',
          'circle-radius': 4,
          'circle-stroke-width': 10,
          'circle-stroke-color': '#0C69F5'
        }
      });

      // inspect a cluster on click

      window.mapbox.on('click', 'clusters', function (e) {
        var features = window.mapbox.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        var clusterId = features[0].properties.cluster_id;
        window.mapbox.getSource('shops').getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err)
            return;

          window.mapbox.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });

        }.bind(this));
      }.bind(this));

      //Popup
      // Create a popup, but don't add it to the map yet.
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true
      });

      /*
      window.mapbox.on('click', 'unclustered-point', function (e) {
        window.location = e.features[0].properties.url;
      }.bind(this));
       */

      window.mapbox.on('click', 'unclustered-point', function (e) {
        window.mapbox.getCanvas().style.cursor = 'pointer';

        var coordinates = e.features[0].geometry.coordinates.slice();
        var title = e.features[0].properties.title;
        var category = e.features[0].properties.category;
        var open = e.features[0].properties.open;
        var url = e.features[0].properties.url;
        var openColor = open ? 'success' : 'danger';

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML('<div class="px-6 pt-6 pb-4"><div class="flex items-center"><h2 class="font-serif text-lg text-midnight">'+title+'</h2><div class="ml-3 w-6px h-6px rounded-full bg-system-'+openColor+'"></div></div><div class="font-sans text-sm text-midnight-400 mt-3">'+category+'</div><a href="'+url+'" class="block font-sans text-sm text-blue mt-3 underline"><div class="map-popup-btn"></div></a></div>').addTo(window.mapbox);
      }.bind(this));

      /*
      window.mapbox.on('mouseleave', 'unclustered-point', function () {
        window.mapbox.getCanvas().style.cursor = '';
        popup.remove();
      }.bind(this));

       */

    },

  }
}


window.veloh = function(url){
  return {
    load: false,
    url: url,
    bikes: 0,
    stands: 0,

    fetch(url){
      fetch(url)
        .then(res => res.json())
        .then(data => {
          this.load = true;
          this.bikes = data.veloh.totalStands.availabilities.bikes;
          this.stands = data.veloh.totalStands.availabilities.stands;
        });
    }
  }
}


window.parking = function(url){
  return {
    load: false,
    url: url,
    actuel: 0,
    complet: 0,
    ouvert: 0,
    tendance: 0,
    total: 0,

    fetch(url){
      fetch(url)
        .then(res => res.json())
        .then(data => {
          this.load = true;
          this.actuel =  !isNaN(parseInt(data.parking.actuel)) ? data.parking.actuel : 0;
          this.complet = !isNaN(parseInt(data.parking.complet)) ? data.parking.complet : 0;
          this.ouvert =  !isNaN(parseInt(data.parking.ouvert)) ? data.parking.ouvert : 0;
          this.tendance =  !isNaN(parseInt(data.parking.tendance)) ? data.parking.tendance : 0;
          this.total = !isNaN(parseInt(data.parking.total)) ? data.parking.total : 0;
        });
    }
  }
}

window.smoothScroll = function(target) {
  if(document.querySelector(target.srcElement.hash)) {
    gsap.to(window, {duration: 2, scrollTo:target.srcElement.hash});
  } else {
    return false
  }
}

window.breakpoint = function(screen) {
  switch (screen) {
    case 'tiny':
    return window.matchMedia("(max-width: 640px)").matches;
    break;
    case 'sm':
    return window.matchMedia("(min-width: 640px)").matches;
    break;
    case 'md':
    return window.matchMedia("(min-width: 768px)").matches;
    break;
    case 'lg':
    return window.matchMedia("(min-width: 1024px)").matches;
    break;
    case 'xl':
    return window.matchMedia("(min-width: 1280px)").matches;
    break;
  }
}

window.cachedWidth = window.innerWidth;
window.changedWidth = function() {
  var hasChanged = false;
  if(window.innerWidth !== window.cachedWidth){
    hasChanged = true;
  }
  setTimeout(function(){ window.cachedWidth = window.innerWidth; }, 1000);
  return hasChanged;
}

window.autocomplete = function(locale){
  return {
    search: null,
    results: [],
    isLoading: false,
    reset(){
      this.q = '';
      this.getSearch();
    },

    getSearch() {
      this.isLoading = true;
      fetch( `/${locale}/api/search?q=${this.search}`)
        .then(res => res.json())
        .then(data => {
          this.isLoading = false;
          this.results = data.autocomplete;
        });
    }
  };
}
