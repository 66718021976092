import Highway from '@dogstudio/highway';
import { Swiper, Navigation } from "swiper";
Swiper.use([Navigation]);

export default class HomeRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {

    const swiperShops = new Swiper(document.getElementById('swiper-shops'), {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            }
        }
    });
    document.getElementById('swiper-shops').style.overflow = 'visible';

  }
  onLeave() {

  }
  onEnterCompleted() {

    let header = document.getElementById('header');
    window.setTimeout(function(){
      let headerHeight = header.offsetHeight;
      let articleHomepage = document.getElementsByTagName('article')[0];
    }, 1);

    var homepageevent = new CustomEvent('homepageevent', {
      detail: { homepageevent: true }
    });
    window.dispatchEvent(homepageevent);

  }
  onLeaveCompleted() {
    var homepageevent = new CustomEvent('homepageevent', {
      detail: { homepageevent: false }
    });
    window.dispatchEvent(homepageevent);
  }
}
