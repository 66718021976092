import * as JsCookies from 'js-cookie';

export default class CookiesConsent {

  constructor(options = {}) {

    // Options
    options.enabledByDefault = options.enabledByDefault || false;

    // Variables
    this.cookies = options.cookies ? options.cookies : [];
    this.hide = JsCookies.get('cookie-hide') ? true : false;

    // Init
    this.init();

  }

  init() {

    // If no bar was found
    if(!document.querySelector('.js-cookies-bar')) {
      console.warn('Cookies bar is not founded !');
      return false;
    }

    // Elements
    this.getElems();

    // Checkboxes system
    this.cookies.forEach(function(cookie) {
      if (JsCookies.get('cookie-agreed-' + cookie.name)){
        document.querySelector('[data-cookies="' + cookie.name + '"]').checked = true;
        cookie.init();
      }

      document.querySelector('[data-cookies="'+cookie.name+'"]').onchange = function(e){
        if(e.target.checked){
          this.enable(cookie);
        }else{
          this.disable(cookie);
        }
      }.bind(this);
    }.bind(this));


    // Hide if the user has already give his consent
    if (this.hide){
      this.hideBar();
    }

    // Remove the default display none (usefull for prevent flick on load)
    if(this.bar.style.display == 'none') {
      this.bar.style.removeProperty('display');
    }

    // Actions
    this.setActions();

  }

  getElems() {
    this.bar = document.querySelector('.js-cookies-bar');
    this.settings = document.querySelector('.js-cookies-bar-settings');
    this.acceptAllBtns = document.querySelectorAll('.js-cookies-btn-accept-all');
    this.refuseAllBtns = document.querySelectorAll('.js-cookies-btn-refuse-all');
    this.openBtns = document.querySelectorAll('.js-cookies-btn-open');
    this.closeBtns = document.querySelectorAll('.js-cookies-btn-close');
    this.saveBtns = document.querySelectorAll('.js-cookies-btn-save');
    this.settingsBtns = document.querySelectorAll('.js-cookies-btn-settings');
  }

  setActions() {

    // Accept all actions
    this.acceptAllBtns.forEach(function(btn){
      btn.onclick = function() {

        //Set on to all
        this.cookies.forEach(function(cookie){
          this.enable(cookie);
        }.bind(this));

        this.hideBar();
      }.bind(this);
    }.bind(this));

    // Refuse all actions
    this.refuseAllBtns.forEach(function(btn){
      btn.onclick = function() {

        //Set off to all
        this.cookies.forEach(function(cookie){
          this.disable(cookie);
        }.bind(this));

        this.hideBar();
      }.bind(this);
    }.bind(this));

    // Open actions
    this.openBtns.forEach(function(btn){
      btn.onclick = function() {
        this.showBar();
      }.bind(this);
    }.bind(this));

    // Close actions
    this.closeBtns.forEach(function(btn){
      btn.onclick = function() {
        if(this.bar.classList.contains(':settings-opened')) {
          this.hideSettings();
        }
        this.hideBar();
      }.bind(this);
    }.bind(this));

    // Save actions
    this.saveBtns.forEach(function(btn){
      btn.onclick = function() {
        if(this.bar.classList.contains(':settings-opened')) {
          this.hideSettings();
        }
        this.hideBar();
      }.bind(this);
    }.bind(this));

    // Show settings
    this.settingsBtns.forEach(function(btn){
      btn.onclick = function() {
        console.log('hello');
        if(this.bar.classList.contains(':closed')) {
          this.showBar();
        }
        this.showSettings();
      }.bind(this);
    }.bind(this));

  }

  enable(cookie){
    JsCookies.set('cookie-agreed-'+cookie.name, 1, {expires: 365} );
    document.querySelector('[data-cookies="'+cookie.name+'"]').checked = true;
    if (cookie.init){
      cookie.init();
      cookie.enabled = true;
      var event = new CustomEvent('cookies', { 'detail': { cookie } });
      document.dispatchEvent(event);
    }
  }

  disable(cookie){
    JsCookies.remove('cookie-agreed-'+cookie.name );
    document.querySelector('[data-cookies="'+cookie.name+'"]').checked = false;
    if (cookie.destroy){
      cookie.destroy();
      cookie.enabled = false;
      var event = new CustomEvent('cookies', { 'detail': { cookie } });
      document.dispatchEvent(event);
    }
  }

  removeCookiesStartWith(cookiesNameStartWith){
    var appCookies = document.cookie.split(";");
    for (var i = 0; i < appCookies.length; i++) {
      var appCookie = appCookies[i];
      var eqPos = appCookie.indexOf("=");
      var name = (eqPos > -1 ? appCookie.substr(0, eqPos) : appCookie).trim();
      cookiesNameStartWith.forEach(function (cookieNameStartWith){
        if (name.indexOf(cookieNameStartWith) == 0){
          JsCookies.remove( name );
        }
      });
    }
  }

  hasConsent(cookieName){
    if(JsCookies.get('cookie-agreed-'+cookieName)) {
      return true;
    } else {
      return false;
    }
  }

  pageChange(to, location){
    this.cookies.forEach(function(cookie) {
      if (JsCookies.get('cookie-agreed-' + cookie.name)){
        if (cookie.pageChange){
          cookie.pageChange(to, location);
        }
      }
    }.bind(this));
  }

  hideBar() {
    this.bar.classList.remove(':opened');
    this.bar.classList.add(':closed');
    JsCookies.set('cookie-hide', 1, {expires: 365} );
  }

  showBar() {
    this.bar.classList.remove(':closed');
    this.bar.classList.add(':opened');
    JsCookies.remove('cookie-hide');
  }

  showSettings() {
    this.bar.classList.remove(':settings-closed');
    this.bar.classList.add(':settings-opened');
  }

  hideSettings() {
    this.bar.classList.remove(':settings-opened');
    this.bar.classList.add(':settings-closed');
  }

  update() {
    this.getElems();
    this.setActions();
  }

}
