import Highway from '@dogstudio/highway';
import { Swiper, Navigation } from "swiper";
Swiper.use([Navigation]);
import Video from '../../video';

export default class PagesRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {

    let swipers = document.querySelectorAll('.swiper-container');
    swipers.forEach(swiper => {

      new Swiper(swiper, {
          slidesPerView: 'auto',
          spaceBetween: 30,
          autoHeight: true,
          loop: true,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
      });
    });
    swipers.forEach(swiper => {
      swiper.style.overflow = 'visible';
    });

    let modalSwipers = document.querySelectorAll('.swiper-container-modal');
    modalSwipers.forEach(swiper => {

      window.modalSwipers = [];
      window.modalSwipers[swiper.id] = new Swiper(swiper, {
          slidesPerView: 'auto',
          spaceBetween: 30,
          autoHeight: true,
          loop: true,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          }
      });

    });

  }
  onLeave() {
    delete window.modalSwipers;
  }
  onEnterCompleted() {
    new Video();
  }
  onLeaveCompleted() {
  }
}
