import Highway from '@dogstudio/highway';

export default class LoginRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    document.getElementById('user-login-form').querySelector('.form-item-name input').setAttribute('autofocus', 'false');
    document.getElementById('user-login-form').querySelector('.form-item-name input').blur();
  }
  onLeave() {
  }
  onEnterCompleted() {
  }
  onLeaveCompleted() {
  }
}
