import Highway from '@dogstudio/highway';

export default class DefaultRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
  }
  onLeave() {
  }
  onEnterCompleted() {
  }
  onLeaveCompleted() {
  }
}
