import Plyr from 'plyr';

export default class Video {

  constructor() {

    Array.from(document.querySelectorAll('.video-player')).map(p => new Plyr(p, {
      youtube: { noCookie: true },
      vimeo: { dnt: true },
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions',  'airplay', 'fullscreen'],
      fullscreen:{ enabled: true, fallback: true, iosNative: true, container: null }
    }));

  }

}
