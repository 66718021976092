import Highway from '@dogstudio/highway';

export default class ShopsRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    //Array.from(document.querySelectorAll('a[data-drupal-facet-item-value]')).map(a => a.setAttribute('data-transition','shops'));
  }
  onLeave() {
  }
  onEnterCompleted() {
  }
  onLeaveCompleted() {
  }
}
