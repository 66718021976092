import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class DefaultTransition extends Highway.Transition {

  out({ from, trigger, done }) {
    gsap.to(from, {duration: .2, opacity: 0, onComplete:done});
    
    var loadingevent = new CustomEvent('loadingevent', {
        detail: { loading: true }
    });
    window.dispatchEvent(loadingevent);
    document.body.style.cursor = "wait";

  }

  in({ from, to, trigger, done }) {
    window.scrollTo(0, 0);
    from.remove();
    gsap.fromTo(to, {opacity: 0}, {duration: .2, opacity: 1, onComplete:done});

    var loadingevent = new CustomEvent('loadingevent', {
        detail: { loading: false }
    });
    window.dispatchEvent(loadingevent);
    document.body.style.cursor = "auto";

  }

}
