import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class ShopsTransition extends Highway.Transition {

  out({ from, trigger, done }) {
    //Remove result and add loader
    done();
  }

  in({ from, to, trigger, done }) {
    window.scrollTo(0, 0);

    setTimeout(function(){

      //Move map
      let currentMap = from.querySelector('#map');
      let newMapWrapper = to.querySelector('#map-wrapper');

      if (currentMap && newMapWrapper){
        newMapWrapper.querySelector('#map').remove();
        newMapWrapper.append(currentMap);
        if (window.mapbox) window.mapbox.resize();
      }

      from.remove();

      done();
    },1);
  }

}
