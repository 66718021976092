import './scss/app.scss';
import 'alpine-magic-helpers'
import 'alpinejs';

import AdminTabs from "./js/adminTabs";
import CookiesConsent from "./js/cookiesConsent";
import Highway from '@dogstudio/highway';

// Renderers
import DefaultRenderer from "./js/highway/renderer/default-renderer";
import HomeRenderer from "./js/highway/renderer/home-renderer";
import ShopRenderer from "./js/highway/renderer/shop-renderer";
import ShopSRenderer from "./js/highway/renderer/shops-renderer";
import EventsRenderer from "./js/highway/renderer/events-renderer";
import ShopareaRenderer from "./js/highway/renderer/shoparea-renderer";
import PageRenderer from "./js/highway/renderer/page-renderer";
import LoginRenderer from "./js/highway/renderer/login-renderer";

//Transitions
import DefaultTransition from "./js/highway/transition/defaultTransition";
import ShopsTransition from "./js/highway/transition/shopsTransition";
import EventsTransition from "./js/highway/transition/eventsTransition";

//Misc
import Alpine from './js/alpine';
import Appear from "./js/appear";

Appear.observe();

//Admin
new AdminTabs();



/***
 * CookiesConsent
 * -----------------------------------------------
 */

window.cookies = new CookiesConsent({
  cookies:[
    {
      name:'analytics',
      init:function(){
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
          var u="//matomo.cityshopping.lu/";
          _paq.push(['setTrackerUrl', u+'piwik.php']);
          _paq.push(['setSiteId', '1']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
        })();
      },
      pageChange:function(to, location){
        _paq.push(['setDocumentTitle', to.page.title]);
        _paq.push(['setCustomUrl', location.href]);
        _paq.push(['trackPageView']);
      },
      destroy:function() {
        window.cookies.removeCookiesStartWith(['_pk']);
      }
    },

    {
      name:'social',
      init:function(){
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.defer = true; g.crossOrigin="anonymous"; g.nonce="dqGXRuWo"; g.src="https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v8.0"; s.parentNode.insertBefore(g,s);
      },
      destroy:function() {
       //window.cookies.removeCookiesStartWith(['yt']);
      }
    },
    {
      name:'mapbox',
      init:function(){
      },
      destroy:function() {
        //window.cookies.removeCookiesStartWith(['yt']);
      }
    },

  ]
});


/***
 * Highway
 */
// Call Highway.Core once.
// Store it in a variable to use events
window.H = new Highway.Core({
    renderers: {
      homepage: HomeRenderer,
      shop: ShopRenderer,
      shops: ShopSRenderer,
      page: PageRenderer,
      news: DefaultRenderer,
      events: EventsRenderer,
      shoparea: ShopareaRenderer,
      login: LoginRenderer,
      default: DefaultRenderer
    },
    transitions: {
      page: DefaultTransition,
      default: DefaultTransition,
      contextual: {
        shops: ShopsTransition,
        events: EventsTransition
      }
    }
  }
);

// Detach admin links
if (document.getElementById('admin-tools')){
  window.H.detach(
    document.querySelectorAll('a')
  )
}

const validURL = function(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

// Update links
const updateLinks = function(){
  const links = document.querySelectorAll('a');

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    link.classList.remove('is-active');
    if (location.href.indexOf(link.href) > -1) {
      link.classList.add('is-active');
    }
    /*

    if (link.href.indexOf(location.href) == -1 && validURL(link.href)) {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    }*/
  }

}
updateLinks();

// Footer positioning
const footerPositioning = function(){
  let footer = document.getElementById('footer-links');
  window.setTimeout(function(){
    let footerHeight = footer.offsetHeight;
    footer.style.marginTop = -footerHeight + "px";
  }, 1);
}
footerPositioning();

// Start apprea animations
Appear.init();

window.H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
  updateLinks();
  footerPositioning();
  Appear.init();

  var footerrandomevent = new CustomEvent('footerrandomevent', {
      detail: { footerImgId: Math.floor(Math.random() * (4 - 1) + 1) }
  });
  window.dispatchEvent(footerrandomevent);

});

window.H.on('NAVIGATE_END', ({ to, location }) => {
  Appear.observe();
  window.cookies.pageChange(to, location);
});

window.H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
  var menuevent = new CustomEvent('menuevent', {
      detail: { menuOpen: false, menuItem: false }
  });
  window.dispatchEvent(menuevent);
});

window.addEventListener('load', () =>{
  updateLinks();
});

window.dec = function(str){
  return decodeURIComponent(escape(window.atob(str)));
}
