import intlTelInput from 'intl-tel-input';

export default class Phone {

  constructor() {


    document.querySelectorAll('.phone-format').forEach(function(input, index){
      //console.log(input.getAttribute('data-hidden-input'));
      //console.log(document.querySelector('form'));

      let telInput = new intlTelInput(input, {
        preferredCountries: ["lu", "de", "be", "fr"],
        separateDialCode: true,
        utilsScript:'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
        hiddenInput:input.getAttribute('data-hidden-input')
      });

    });
  }

}
