import Highway from '@dogstudio/highway';
import { Swiper, Navigation } from "swiper";
Swiper.use([Navigation]);

export default class ShopRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    window.swiperPics = null;
    window.swiperProducts = null;
    window.swiperOpenings = null;
  }
  onLeave() {
    window.swiperPics = null;
    window.swiperProducts = null;
    window.swiperOpenings = null;

  }
  onEnterCompleted() {

    if(document.getElementById('swiper-pics')) {
      window.swiperPics = new Swiper(document.getElementById('swiper-pics'), {
          slidesPerView: 1,
          spaceBetween: 30,
          autoHeight: true,
          updateOnImagesReady: true,
          preloadImages: true,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
          breakpoints: {
              640: {
                  slidesPerView: 'auto'
              },
          }
      });
    }

    if(document.getElementById('swiper-products')) {
      window.swiperProducts = new Swiper(document.getElementById('swiper-products'), {
          slidesPerView: 1,
          spaceBetween: 30,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
          breakpoints: {
              640: {
                  slidesPerView: 2
              },
              1024: {
                  slidesPerView: 3
              },
          }
      });
    }

    if(document.getElementById('swiper-openings')) {
      window.swiperOpenings = new Swiper(document.getElementById('swiper-openings'), {
          slidesPerView: 1,
          spaceBetween: 30,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
          breakpoints: {
              640: {
                  slidesPerView: 2
              },
              1024: {
                  slidesPerView: 3
              },
          }
      });
    }

    if (window.cookies.hasConsent('social')){
      FB.XFBML.parse();
    }

  }
  onLeaveCompleted() {
  }
}
