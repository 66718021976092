import Highway from '@dogstudio/highway';
import Phone from '../../phone';

export default class ShopareaRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {

    new Phone();

  }
  onLeave() {
  }
  onEnterCompleted() {
  }
  onLeaveCompleted() {
  }
}
