import { gsap } from "gsap";

export default class Appear {

  static init(){

    if(document.querySelectorAll('.js-appear')) {
      gsap.timeline()
      .set(document.querySelectorAll('.js-appear'), {y: 50, opacity: 0}, 0);
      document.querySelectorAll('.js-appear').forEach(function(entry) {
        if(!entry.classList.contains('block')) {
          entry.classList.add('block');
        }
        entry.removeAttribute('data-played');
      });
    }

  }

  static observe(){

    if(document.querySelectorAll('.js-appear')) {

      if ("IntersectionObserver" in window) {

        IntersectionObserver.prototype.POLL_INTERVAL = 100; // Time in milliseconds

        // Appear block
        let lazyElementsObserver = new IntersectionObserver(function (entries, observer) {
          let elements = [];
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {
              if(!entry.target.dataset.played){
                entry.target.dataset.played = true;
                elements = elements.concat(entry.target);
              }
            }
          });
          if(elements.length > 0) {
            Appear.appear(elements);
          }
        });
        document.querySelectorAll('.js-appear').forEach(function(element) {
          lazyElementsObserver.observe(element);
        });

      }

    }

  }

  static appear(element = 0) {
    gsap.to( element, {
      opacity: 1,
      y: 0,
      ease: "expo.out",
      duration: 1,
      delay: .25,
      stagger:{
        amount: .2,
        from: 0
      }
    });
  }

}
