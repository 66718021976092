import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class EventsTransition extends Highway.Transition {

  out({ from, trigger, done }) {
    //Remove result and add loader
    done();
  }

  in({ from, to, trigger, done }) {
    window.scrollTo(0, 0);
    from.remove();
    done();
  }

}
